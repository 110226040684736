.section {
  text-align: center;
  overflow: hidden;
  @include tablets {
    margin: 0 auto;
    width: 90%;
    position: relative;
  }
}

#test {
  width: 100px;
  height: 50px;
  position: fixed;
  z-index: 1000;
  @include test1 {
    background: #ccc;
  }
  @include test2 {
    background: red;
  }
  @include test3 {
    background: black;
  }
}

.section_inn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.block_column {
  display: flex;
  flex-direction: column;
  position: relative;

}

.block_row {
  display: flex;
  flex-direction: row;
  &.just_center {
    justify-content: center;
  }
}

.vert_center {
  justify-content: center;
  align-items: center;
  @include hd4 {
    width: 40%;
  }
  @include tablets_min{
    width: 100%;
  }
}

#section0 {
  padding: 60px 0;
  & .block_row {
    justify-content: space-around;
    height: 95%;
    @include desktops{
      width: 95%;
    }
  }
}

#section1 {
  padding: 60px 0;
  & ul {
    color: #5a5a5a;
    list-style-type: none;
    text-align: left;
  }
  @include tablets {
    padding: 20px 0;
  }
}

#section2 {
  padding: 60px 0;
  & .list {
    justify-content: center;
  }
  & .block_column {
    justify-content: center;
    height: 95%;
    &.list {
      height: auto;
      width: 35%;
      @include hdlaptops {
        width: 22%;
      }
    }
  }
  & .block_row {
  }
  & h2 {
    margin-bottom: 70px;
    @include hdlaptops {
      margin-bottom: 20px;
    }
  }
  & .block_row {
    @include tablets {
      flex-direction: column-reverse;
      height: auto;
      & .list {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  @include tablets {
    padding: 20px 0;
  }
}

#section3 {
  padding: 60px 0;
  & .block_column {
    justify-content: center;
    height: 95%;
    @include laptops {
      height: auto;
    }
  }
  & .block_row {
    padding-bottom: 2%;
    @include tablets {
      flex-direction: column;
      height: auto;
    }
  }
  @include tablets {
    padding: 20px 0;
  }
}

#section4 {
  padding: 60px 0;
  & strong {
    color: #000000;
    font-size: 18px;
    margin: 10px 0 5px 0;
    display: inline-block;
    @include desktops {
      font-size: 16px;
    }
    @include hd3 {
      font-size: 16px;
    }
    @include laptops {
      font-size: 12px;
    }
    @include hdlaptopsdeg {
      font-size: 12px;
    }
    @include hd4 {
      font-size: 12px;
    }
  }
  & .swiper-container2 {
    & .block_row {
      flex-direction: row;
      height: auto;
      width: auto;
      justify-content: center;
      @include tablets {
        height: auto;
        flex-direction: row;
      }
    }
  }
  & .block_row {
    @include tablets {
      flex-direction: column;
    }
  }
  @include tablets {
    padding: 20px 0;
  }
}

#section5 {
  & .section_inn {
    @include tablets {
      justify-content: unset;
      display: block;
      align-items: unset;
    }
  }
  @include tablets {
    padding: 20px 0;
  }
  & h2 {
    @include tablets_min {
      margin-bottom: 25px;
    }
  }
}

.centr_block_row {
  flex-direction: row;
  display: flex;
}

.doptext {
  font-family: 'RotondaC-Bold';
  font-size: 22px;
  color: #1b5db2;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0px 2px 2px #ffffff;
  margin-bottom: 10px;
  @include desktops {
    font-size: 18px;
  }
  @include hd3 {
    font-size: 18px;
  }
  @include laptops {
    font-size: 16px;
  }
  @include hdlaptopsdeg {
    font-size: 14px;
  }
  @include hd4 {
    font-size: 14px;
  }
  @include hdlaptops {
    font-size: 14px;
  }
}

.pred {
  font-family: 'RotondaC-Bold';
  font-size: 22px;
  color: #0051ad;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0px 2px 2px #ffffff;
  text-align: left;
  margin-bottom: 10px;
  padding-left: 18px;
  @include hd2 {
    font-size: 20px;
  }
  @include desktops {
    font-size: 18px;
  }
  @include hd3 {
    font-size: 18px;
  }
  @include laptops {
    font-size: 16px;
  }
  @include hdlaptops {
    font-size: 14px;
  }
  @include hdlaptopsdeg {
    font-size: 14px;
  }
  @include hd4 {
    font-size: 14px;
  }
  @include tablets {
    padding: 0;
    width: 100%;
    text-align: center;
  }
}

.block_column {
  &.list {
    width: 300px;
  }
  &.swiper-slide {
    width: auto;
  }
}

h1 {
  font-family: 'RotondaC-Bold';
  font-size: 42px;
  text-shadow: 0px 2px 2px rgba(0, 88, 153, 1);
  @include hd2 {
    font-size: 38px;
  }
  @include desktops {
    font-size: 30px;
  }

  @include hdlaptops {
    font-size: 24px;
  }
  @include hdlaptopsdeg {
    font-size: 20px;
  }
  @include hd3 {
    font-size: 30px;
  }
  @include hd4 {
    font-size: 24px;
  }
}

h2 {
  font-family: 'RotondaC-Bold';
  font-size: 42px;
  text-shadow: 0px 2px 2px rgba(0, 88, 153, 1);
  @include hd2 {
    font-size: 38px;
  }
  @include desktops {
    font-size: 30px;
    margin-bottom: 0;
  }


  @include laptops {
    font-size: 24px;
  }
  @include hdlaptopsdeg {
    font-size: 20px;
  }

  @include hdlaptops {
    font-size: 20px;
  }
  @include tablets {
    font-size: 30px;
  }
  @include hd3 {
    font-size: 30px;
    margin-bottom: 0;
  }
  @include hd4 {
    font-size: 30px;
  }
}

.h2 {
  font-family: 'RotondaC-Bold';
  color: #0051ad;
  font-size: 42px;
  text-shadow: 0px 2px 2px #ffffff;
  margin: 40px 0;
}

h3 {
  font-family: 'RotondaC-Bold';
  font-size: 22px;
  color: #0051ad;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0px 2px 2px #ffffff;
  @include hd2 {
    font-size: 20px;
  }
  @include desktops {
    font-size: 18px;
  }
  @include hd3 {
    font-size: 18px;
  }
  @include laptops {
    font-size: 16px;
  }
  @include hdlaptopsdeg {
    font-size: 14px;
  }
  @include hd4 {
    font-size: 14px;
  }
  @include hdlaptops {
    font-size: 14px;
  }
}

p {
  color: #5a5a5a;
  & strong {
    color: #000000;
  }
}

.cursor {
  cursor: pointer;
  position: absolute;
  @include centered();
  width: 188px;
  height: 188px;
  z-index: 100;
  @include round();
  background-color: #ffffff;
  @include tablets {
    display: none;
  }
  @include hdlaptopsdeg {
    width: 130px;
    height: 130px;
  }

}

.curs_inn {
  position: absolute;
  @include centered();
  width: 136px;
  height: 136px;
  @include round();
  border: 1px solid #b2cdff;
  background-color: #ffffff;
  & img {
    position: absolute;
    @include centered();
    width: 90px;
    height: 90px;
    @include hdlaptopsdeg {
      width: 50px;
      height: 50px;
    }


  }
  @include hdlaptopsdeg {
    width: 90px;
    height: 90px;
  }

}

.down {

  margin: 0 auto;
  position: absolute;
  padding-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 20px;
  @include tablets {
    position: relative;
  }
  &.abs {
    position: absolute;
    bottom: 20px;
    @include tablets {
      position: relative;
    }
  }
  @include tablets {
    position: relative;
    margin-top: 20px;
    & .doptext {
      margin: 50px auto 20px auto;
    }
  }
}

.down_in {
  width: 48px;
  height: 19px;
  background-image: url("/assets/img/down_blue.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 0 auto;
}

.how {
  justify-content: space-around;
  height: 80%;
  align-items: center;
  & span {
    font-size: 28px;
    color: #92b2e0;
    @include desktops {
      font-size: 20px;
    }
    @include hd3 {
      font-size: 20px;
    }
  }
  & .block_row {
    align-items: flex-end;
    justify-content: center;
  }
  & .pred {
    padding: 0;
    text-align: center;
    margin: 35px 0;
    @include hd2 {
      margin: 20px 0;
    }
    @include desktops {
      margin: 10px 0;
    }
    @include hd3 {
      margin: 10px 0;
    }
  }
  & .block_column {
    width: 370px;
    @include laptops {
      width: 300px;

    }
  }
}

.freq {
  font-size: 22px;
  color: #5a5a5a;
  margin: 30px 0;
  @include hd2 {
    font-size: 20px;
    margin: 10px 0;
  }
  @include desktops {
    font-size: 18px;
  }
  @include hdlaptopsdeg {
    font-size: 14px;
  }
  @include hdlaptops {
    font-size: 14px;
  }
  @include hd3 {
    font-size: 18px;
  }
  @include hd4 {
    font-size: 16px;
  }
}

.buuton_byu {
  font-size: 22px;
  color: #0051ad;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 25px;
  padding: 10px 20px;
  border: 1px solid #30aadf;
  width: 18%;
  max-width: 210px;
  text-align: center;
  transition: all 0.5s ease-in-out;
  &:hover {
    background: #0051ad;
    color: #ffffff;
  }
  @include desktops {
    font-size: 18px;
  }
  @include hd3 {
    font-size: 18px;
  }
  @include hdlaptopsdeg {
    font-size: 14px;
    max-width: 120px;
  }
  @include hd4 {
    font-size: 14px;
    max-width: 120px;
  }
  @include hdlaptops {
    font-size: 14px;
    max-width: 120px;
  }
  @include tablets_min {
    width: 40%;
  }
}

.spok {
  justify-content: center;
  align-items: center;
  & h1 {
    display: inline-block;
    width: 70%;
  }
  @include tablets {
    display: none;
  }
}

.for_logo {
  width: 50%;
  max-width: 242px;
  & img {
    width: 100%;
    max-width: 360px;
    height: auto;
    text-align: center;
    @include hdlaptops {
      width: 80%;
    }

  }
}

.maluk {
  max-height: 390px;
  height: 40vh;
  width: auto;
  padding: 3% 0;
  @include hdlaptopsdeg {
    height: 38vh;
  }
  @include hd4 {
    height: 38vh;
  }
  @include tablets {
    width: 100%;
    height: auto;
  }
}

.bigboy {
  max-height: 400px;
  height: 60vh;
  width: auto;
  @include desktops {
    height: 50vh;
  }
  @include hd3 {
    height: 50vh;
  }
  @include laptops {
    height: 43vh;
  }
  @include hdlaptopsdeg {
    height: 50vh;
  }
  @include hd4 {
    height: 50vh;
  }
  @include tablets {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }
  @include tablets_min {
    width: 90%;
    margin: 20px auto;
  }
}

.bigpack {
  height: 60vh;
  max-height: 420px;
  width: auto;
}

.for_pack {
  & img {
    max-height: 400px;
    height: 50vh;
    width: auto;
    @include hd2 {
      height: 45vh;
    }
    @include desktops {
      height: 40vh;
    }
    @include hd3 {
      height: 40vh;
    }
  }
}

.obl {
  position: relative;
  width: 300px;
  @include desktops {
    width: 265px
  }

  @include hdlaptopsdeg {
    width: 170px;
  }

  @include tablets {
    width: 225px;
  }
  @include hd3 {
    width: 265px
  }
  @include hd4 {
    width: 265px;
  }
  & p {
    font-family: 'RotondaC-Bold';
    font-size: 22px;
    color: #0051ad;
    font-weight: bold;
    text-shadow: 0px 2px 2px #ffffff;
    width: 70%;
    text-align: center;
    display: inline-block;
    position: absolute;
    @include centered();
    @include desktops {
      font-size: 20px;
    }

    @include laptops {
      font-size: 18px;
    }
    @include hdlaptopsdeg {
      font-size: 16px;
    }

    @include hd3 {
      font-size: 18px;
    }
    @include hd4 {
      font-size: 20px;
    }
  }

}

.skladovi {
  justify-content: center;
  height: 95%;
  & h2 {
    margin-bottom: 70px;
    @include hd {
      margin-bottom: 35px;
    }
    @include desktops {
      margin-bottom: 25px;
    }
    @include hd3 {
      margin-bottom: 25px;
    }
    @include laptops {
      margin-bottom: 15px;
    }
  }
  @include tablets {
    display: block;
    height: auto;
    width: 100%;
  }
}

.center_boy {
  &:before {
    content: '';
    width: 25px;
    height: 100%;
    position: absolute;
    background-image: url("/assets/img/line-left.png");
    background-repeat: no-repeat;
    left: 0px;
    background-position: center right;
  }
  &:after {
    content: '';
    width: 25px;
    height: 100%;
    position: absolute;
    background-image: url("/assets/img/line-right.png");
    background-repeat: no-repeat;
    right: 0px;
    background-position: center right;
  }
}

.oblinn {
  width: 100%;
  height: auto;
}

.bl_col {
  //text-align: left;
  margin-left: 20px;
}

.simptom {
  color: #5a5a5a;
  font-size: 18px;
  text-align: left;
  list-style-type: none;
  & li {
    display: flex;
    margin: 14px 0;
    @include laptops {
      margin: 12px 0;
    }
    @include hdlaptopsdeg {
      margin: 10px 0;
    }
    @include hd4 {
      margin: 10px 0;
    }
    &:before {
      content: '•';
      display: flex;
      color: #4f85f5;
      margin-right: 8px;
    }
  }
  @include desktops {
    font-size: 16px;
  }

  @include laptops {
    font-size: 12px;
  }
  @include hdlaptopsdeg {
    font-size: 12px;
  }
  @include hdlaptops {
    font-size: 12px;
  }
  @include hd3 {
    font-size: 16px;
  }
  @include hd4 {
    font-size: 16px;
  }
}

.right_selent {
  width: 60%;
  & p {
    text-align: left;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 24px;
    display: inline-table;
    @include desktops {
      font-size: 16px;
      line-height: 18px;
    }

    @include laptops {
      font-size: 12px;
      line-height: 14px;
    }
    @include hdlaptopsdeg {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 14px;
    }
    @include hdlaptops {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 14px;
    }
    @include hd3 {
      font-size: 16px;
      line-height: 18px;
    }
    @include hd4 {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 14px;
    }
  }
  & .h2 {
    font-size: 42px;
    text-align: center;
    line-height: normal;
    @include hd2 {
      font-size: 38px;
    }
    @include desktops {
      font-size: 30px;
    }

    @include laptops {
      font-size: 24px;
    }
    @include hdlaptopsdeg {
      font-size: 20px;
    }

    @include hdlaptops {
      font-size: 20px;
    }
    @include hd3 {
      font-size: 30px;
    }
    @include hd4 {
      font-size: 30px;
    }
  }
  & .for_logo {
    width: 100%;
    max-width: none;
    @include laptops {
      width: 50%;
      display: block;
      margin: 0 auto;
    }
    @include hdlaptopsdeg {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    @include hd4 {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
    @include hdlaptops {
      width: 40%;
      display: block;
      margin: 0 auto;
    }
  }
  @include tablets {
    width: 80%;
    margin: 0 auto;
  }
}

.dbl_row {
  @include tablets {
    display: flex;
  }
  &.swiper-wrapper {
    @include tablets {
      flex-direction: row;
    }
  }
}

.icon {
  width: 203px;
  max-width: 172px;
  height: 245px;
  max-height: 207px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    transform: scale(0.7);
  }

  @include hdlaptopsdeg {
    width: 86px;
    height: 103px;
  }
  @include hdlaptops {
    width: 120px;
    height: 144px;
  }
  @include hd3 {
    transform: scale(0.7);
  }
  @include hd4 {
    width: 86px;
    height: 103px;
  }

  @include laptops {
    width: 120px;
    height: 144px;
  }
}

.vpliv {
  justify-content: center;
  height: 100%;
  width: 90%;
  & .block {
    text-align: center;
    width: 30%;
    font-size: 18px;
    @include desktops {
      font-size: 16px;
    }

    @include laptops {
      font-size: 12px;
    }
    @include hdlaptops {
      font-size: 14px;
    }
    @include hdlaptopsdeg {
      font-size: 12px;
    }
    @include hd3 {
      font-size: 16px;
    }
    @include hd4 {
      font-size: 16px;
    }
  }
  & .pred {
    font-family: 'RotondaC-Bold';
    text-align: center;
    padding: 0;
    margin: 30px 0;
    @include hd {
      margin: 20px 0;
    }
    @include desktops {
      margin: 10px 0;
    }
    @include hd3 {
      margin: 10px 0;
    }
  }
  & .block_row {
    justify-content: space-between;
  }
}

.emo {
  & li {
    display: flex;
    margin: 10px 0;
    &:before {
      content: '•';
      display: flex;
      color: #4f85f5;
      margin-right: 8px;
    }
  }
}

.roz {
  & li {
    display: flex;
    margin: 10px 0;
    &:before {
      content: '•';
      display: flex;
      color: #09e26d;
      margin-right: 8px;
    }
  }
}

.fiz {
  & li {
    display: flex;
    margin: 10px 0;
    &:before {
      content: '•';
      display: flex;
      color: #ffd800;
      margin-right: 8px;
    }
  }
}

.bl_text {
  & h3 {
    margin-top: 0;
  }
  & p {
    font-size: 18px;
    line-height: 24px;
    @include desktops {
      font-size: 16px;
      line-height: 20px;
    }

    @include laptops {
      font-size: 12px;
      line-height: 14px;
    }
    @include hdlaptopsdeg {
      font-size: 12px;
      line-height: 14px;
    }
    @include hdlaptops {
      font-size: 12px;
      line-height: 14px;
    }
    @include hd3 {
      font-size: 16px;
      line-height: 20px;
    }
    @include hd4 {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.dbl_row {
  align-items: flex-end;
  & .block_row:nth-child(2) {
    margin-top: 20px;
  }
}

.ml1 {
  width: 213px;
  height: 174px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    width: 149px;
    height: 121px;
  }
  @include hd3 {
    width: 149px;
    height: 121px;
  }
  @include hdlaptopsdeg {
    width: 104px;
    height: 84px;
  }
  @include hd4 {
    width: 104px;
    height: 84px;
  }
  @include hdlaptops {
    width: 104px;
    height: 84px;
  }
}

.ml2 {
  width: 176px;
  height: 172px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    width: 123px;
    height: 120px;
  }
  @include hd3 {
    width: 123px;
    height: 120px;
  }
  @include hdlaptopsdeg {
    width: 86px;
    height: 84px;
  }
  @include hd4 {
    width: 86px;
    height: 84px;
  }
  @include hdlaptops {
    width: 86px;
    height: 84px;
  }
}

.ml3 {
  width: 187px;
  height: 174px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    width: 130px;
    height: 121px;
  }
  @include hd3 {
    width: 130px;
    height: 121px;
  }
  @include hdlaptopsdeg {
    width: 86px;
    height: 84px;
  }
  @include hd4 {
    width: 86px;
    height: 84px;
  }
  @include hdlaptops {
    width: 86px;
    height: 84px;
  }
}

.ml4 {
  width: 156px;
  height: 174px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    width: 108px;
    height: 121px;
  }
  @include hd3 {
    width: 108px;
    height: 121px;
  }
  @include hdlaptopsdeg {
    width: 86px;
    height: 84px;
  }
  @include hd4 {
    width: 86px;
    height: 84px;
  }
  @include hdlaptops {
    width: 86px;
    height: 84px;
  }
}

.romashka {
  width: 195px;
  height: 173px;
  @include hd {
    transform: scale(0.9);
  }
  @include desktops {
    width: 136px;
    height: 121px;
  }
  @include hd3 {
    width: 136px;
    height: 121px;
  }
  @include hdlaptopsdeg {
    width: 95px;
    height: 84px;
  }
  @include hd4 {
    width: 95px;
    height: 84px;
  }
  @include hdlaptops {
    width: 95px;
    height: 84px;
  }
}

.bl_col.bl_text {
  text-align: left;
  & h3 {
    text-align: center;
    margin-top: 15px;
  }
}

.for_big_pack {
  justify-content: center;
}

.boys {
  width: 197px;
  max-width: 160px;
  height: 194px;
  max-height: 157px;
  @include desktops {
    width: 128px;
    height: 125px;
  }
  @include hd3 {
    width: 128px;
    height: 125px;
  }
  @include hdlaptopsdeg {
    width: 89px;
    height: 87px;
  }
  @include hd4 {
    width: 89px;
    height: 87px;
  }
  @include hdlaptops {
    width: 89px;
    height: 87px;
  }
}

.glass {
  width: 108px;
  height: 111px;
  @include desktops {
    width: 75px;
    height: 77px;
  }
  @include hd3 {
    width: 75px;
    height: 77px;
  }
}

.pack_4 {
  height: 55vh;
  max-height: 420px;
  width: auto;
  margin-right: 50px;
  margin-top: 50px;
  @include hdlaptops {
    height: 45vh;
  }
  @include laptops {
    height: 42vh;
  }
  @include tablets {
    width: 50%;
    height: auto;
    margin: 20px auto;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
  height: auto;
}

.swiper-container2 {
  display: flex;

  & .swiper-slide {
    width: 24%;
  }
  @include tablets {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    height: auto;
  }
}

.swiper-container3 {
  margin: 0 auto;
  position: relative;
  //overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  & .swiper-wrapper {
    align-items: center;
    @include tablets {
      justify-content: unset;
    }
  }

}

.swiper-slide {
  background: transparent;
}

.swiper-button-next {
  display: none;
}

.swiper-button-prev {
  display: none;
}

.lig {
  width: 80%;
  color: #5a5a5a;
  padding-top: 10px;
  font-size: 12px;
  & span {
    font-size: 12px;
    color: #5a5a5a;
    display: inline-block;
    @include hdlaptopsdeg {
      font-size: 10px;
    }
    @include hd4 {
      font-size: 10px;
    }
    @include hdlaptops {
      font-size: 10px;
    }
    @include tablets {
      font-size: 8px;
    }
  }
  @include tablets_min {
    padding-bottom: 20px;
  }
  @include hdlaptopsdeg {
    font-size: 10px;
  }
  @include hd4 {
    font-size: 10px;
  }
  @include hdlaptops {
    font-size: 10px;
  }
  @include tablets {
    width: 90%;
    font-size: 8px;
  }
}