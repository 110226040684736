@import 'common/mixins';

html{
  font-size: 16px;
  //font-size: 2vmin;
  //font-size: 0.8rem;
  overflow-x: hidden;


}


body {
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #fff;
  font-family: 'RotondaC';
  font-size: 16px;
  background-color: transparent;
}

ul, li, p {
  margin: 0;
  padding: 0;
}

a {
  color: #fff;
}

.clear{
  clear: both;
  height: 0px;
  width: 100%;
}

.for_des_back{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  & img{
    width: 100%;
    height: 100%;
  }
}

@import 'common/fonts';
@import 'common/header';
@import 'common/_menu_widget';
@import 'common/index';
@import 'common/fullpage';
@import 'common/swiper.min';
@import 'common/articles';
@import 'common/animate';




