$varCircHtml5: 0.85;
@mixin testM($var1, $var2) {
  color: $var1;
  background: $var2;
}


@mixin round(){
  border-radius: 50%;
}

@mixin centered($axis:'both'){
  @if ($axis == 'both'){
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @if $axis == 'x'{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == 'y'{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == 'none'{
    top:0;
    left: 0;
    transform: translate(0, 0);
  }
}

@mixin phones(){
  @media screen and (max-width:480px) {@content;}
}
@mixin tablets_min(){
  @media screen and (max-width:768px) {@content;}
}
@mixin tablets(){
  @media screen and (max-width:800px) {@content;}
}

@mixin laptops(){
  @media screen and (max-width:1024px) {@content;}
}
@mixin hdlaptopsdeg(){
  @media screen and (max-width:1280px) and (max-height:500px) {@content;}
}
@mixin hdlaptops(){
  @media screen and (max-width:1280px) {@content;}
}
@mixin desktops(){
  @media screen and (max-width:1366px) {@content;}
}
@mixin hd(){
  @media screen and (max-width:1440px) {@content;}
}
@mixin hd3(){
  @media screen and (max-width:1600px) and (min-resolution: 100dpi) {@content;}
}
@mixin hd4(){
  @media screen and (max-width:1600px) and (min-resolution: 125dpi) {@content;}
}


@mixin hd2(){
  @media screen and (max-width:1600px) {@content;}
}

@mixin test1(){ @media (min-resolution: 72dpi) {
  @content;
}}

@mixin test2(){ @media (min-resolution: 100dpi) {
  @content;
}}
@mixin test3(){ @media (min-resolution: 125dpi) {
  @content;
}}


@mixin underline {
  &:after {
    content: '';
    display: block;
    width: 90px;
    height: 3px;
    background: #474b57;
    margin: 0 auto;
    margin-top: 21px;
    @include pc{margin-top: 10px;}
    @include iphone{margin-top:24px; }
  }
}