@font-face {
  font-family: 'RotondaC';
  src: url('../fonts/RotondaC.eot');
  src: url('../fonts/RotondaC.woff2') format('woff2'),
  url('../fonts/RotondaC.woff') format('woff'),
  url('../fonts/RotondaC.ttf') format('truetype'),
  url('../fonts/RotondaC.svg#RotondaC') format('svg'),
  url('../fonts/RotondaC.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RotondaC-Bold';
  src: url('../fonts/RotondaC-Bold.eot');
  src: url('../fonts/RotondaC-Bold.woff2') format('woff2'),
  url('../fonts/RotondaC-Bold.woff') format('woff'),
  url('../fonts/RotondaC-Bold.ttf') format('truetype'),
  url('../fonts/RotondaC-Bold.svg#RotondaC-Bold') format('svg'),
  url('../fonts/RotondaC-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello.eot?38042623");
  src: url("../fonts/fontello.eot?38042623#iefix") format("embedded-opentype"), url("../fonts/fontello.woff2?38042623") format("woff2"), url("../fonts/fontello.woff?38042623") format("woff"), url("../fonts/fontello.ttf?38042623") format("truetype"), url("../fonts/fontello.svg?38042623#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?38042623#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before {
  content: '\e800';
}

/* '' */
.icon-ok:before {
  content: '\e801';
}

/* '' */
.icon-ok-circled:before {
  content: '\e802';
}

/* '' */
.icon-home:before {
  content: '\e803';
}

/* '' */
.icon-cancel:before {
  content: '\e804';
}

/* '' */
.icon-cancel-circled:before {
  content: '\e805';
}

/* '' */
.icon-ok-circled2:before {
  content: '\e806';
}

/* '' */
.icon-cancel-circled2:before {
  content: '\e807';
}

/* '' */
.icon-plus:before {
  content: '\e808';
}

/* '' */
.icon-eye:before {
  content: '\e809';
}

/* '' */
.icon-right-open:before {
  content: '\e80a';
}

/* '' */
.icon-left-open:before {
  content: '\e80b';
}

/* '' */
.icon-search:before {
  content: '\e80c';
}

/* '' */
.icon-users:before {
  content: '\e80d';
}

/* '' */
.icon-phone:before {
  content: '\e80e';
}

/* '' */
.icon-mail-alt:before {
  content: '\f0e0';
}

/* '' */
.icon-angle-double-left:before {
  content: '\f100';
}

/* '' */
.icon-angle-double-right:before {
  content: '\f101';
}

/* '' */
.icon-angle-left:before {
  content: '\f104';
}

/* '' */
.icon-angle-right:before {
  content: '\f105';
}

/* '' */
.icon-angle-circled-left:before {
  content: '\f137';
}

/* '' */
.icon-angle-circled-right:before {
  content: '\f138';
}

/* '' */
.icon-angle-circled-up:before {
  content: '\f139';
}

/* '' */
.icon-angle-circled-down:before {
  content: '\f13a';
}

/* '' */
.icon-ok-squared:before {
  content: '\f14a';
}


